<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment v-if="cotizadorHotels.ocupation[indexOcupation].showAllPaxs || pax.principal">
    <v-col
      cols="12"
      md="12"
      class="mt-0 pt-0 mb-0 pb-0"
    >
      <h5>{{ index > 0 ? `${$t('lbl.huesp')} ${index + 1}` : $t('lbl.huespPrincipal') }}</h5>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="pax.name"
        :label="`${$t('lbl.name')}`"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="pax.apellidos"
        :label="`${$t('lbl.apellidos')}`"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="pax.noDocument"
        :label="`${$t('lbl.noDocument')}`"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    pax: {
      type: Object,
    },
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    indexOcupation: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      image: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
      },
      is_nivel_propietario: false,
    }
  },
  computed: {
    ...mapState({
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
    }),
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations([
      'updateOcupationHotel',
      'setCotizadorHotelSelectPriceTotal',
      'setShowRoomHotelCotizador',
      'setCotizadorHotelsRomms',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
            this.is_nivel_propietario = true
            this.infoImportant = true
          }
        })
        .catch(error => console.log(error))
    },
  },
}
</script>
